import React from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';

const NotFoundController = () => {
  const [t] = useTranslation();
  return (
    <>
      <div className="team-page-controller">
        <Flex
          style={{
            width: '100%',
            marginTop: '2rem',
          }}
          justify="center"
        >
          {t('Page not found')}
        </Flex>
      </div>
    </>
  );
};
export default NotFoundController;
