import React, { useEffect, useState } from 'react';

import { ConfigProvider, Divider, Flex, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStatsPlaceholders } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

import StatisticsDetailsMultitoolBlock from '../StatisticsDetailsMultitoolBlock';

import './index.css';

const MetricsSelector = ({
  metricsControllerRef,
}: {
  metricsControllerRef: any;
}) => {
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { setTeamPlayerStats_stats } = teamPlayerStatsSectionReducer.actions;
  const { teamPlayerStats_stats } = useSelector(
    (state: AppStateType) => state.teamPlayerStatsSectionReducer,
  );
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  useEffect(() => {
    openedTeamPlayer &&
      dispatch(
        setTeamPlayerStats_stats(
          makeStatsPlaceholders(t, openedTeamPlayer.team.id),
        ),
      );
  }, [openedTeamPlayer]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSeason, setSelectedSeason] = useState<string | number>();
  const [metricsState, setMetricsState] = useState(t('Statistics'));

  const showStat = (stat: any) => {
    return (
      openedTeamPlayer &&
      (stat.for_sport?.length === 0 ||
        (stat.for_sport?.length &&
          stat.for_sport.includes(openedTeamPlayer?.team.sport))) &&
      !stat.excluded_sports?.includes(openedTeamPlayer?.team.sport)
    );
  };
  return (
    <Flex
      gap={0}
      vertical
      justify="flex-start"
      style={{
        background: 'white',
        borderRadius: 12,
        height: 'calc(100vh - 96px - 56px - 12px)', // высота блока - игрок - шапка - отступ внизу
        maxHeight: `calc(${teamPlayerStats_stats?.filter((stat: any) => showStat(stat)).length * 42 + 7 + 38 + 32 + 200}px)`,
        overflowY: 'hidden',
      }}
      className="player-multitool-stats-wrapper"
    >
      <Flex
        align="center"
        justify="center"
        style={{ marginLeft: 8, marginRight: 8, height: 48, marginTop: 8 }}
      >
        <ConfigProvider
          theme={{
            components: {
              Segmented: {
                trackBg: 'var(--segmentedGroupBg)',
                itemColor: 'red',
              },
            },
            token: {
              lineHeight: 22,
            },
          }}
        >
          <Segmented
            style={{ width: '100%' }}
            size="middle"
            color="black"
            block
            height={32}
            value={metricsState}
            options={[t('Statistics'), t('Context')]}
            onChange={(value) => {
              setMetricsState(value); // string
            }}
          />
        </ConfigProvider>
      </Flex>
      <Flex
        style={{
          paddingLeft: 16,
          paddingRight: 8,
          paddingTop: 12,
        }}
        gap={0}
      >
        <Flex flex={1} className="stats-name">
          {t('Actions')}
        </Flex>
        <Flex gap={8}>
          <div
            className="stats-header"
            style={{
              minWidth: 130,
              flex:
                openedTeamPlayer && openedTeamPlayer.team.sport === 2
                  ? 'unset'
                  : 1,
              textAlign: 'start',
            }}
          >
            {t('Total')}
          </div>
          {openedTeamPlayer && openedTeamPlayer.team.sport !== 2 && (
            <div className="stats-header" style={{ width: 56 }}>
              {t('90 min')}
            </div>
          )}
        </Flex>
      </Flex>
      <Divider style={{ marginBottom: 7, marginTop: 4 }} />
      <StatisticsDetailsMultitoolBlock
        selectedSeason={selectedSeason}
        rightMargin={4}
        metricsControllerRef={metricsControllerRef}
      />
    </Flex>
  );
};
export default MetricsSelector;
