import React, { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ConfigProvider, Flex, Spin, Table, TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import './index.css';
import { ClubsAPI } from 'api/clubs';
import { AppStateType } from 'reducers';
import { PLAYER_ROLES_ABBREVIATED } from 'types/constants';

const TeamPlayersTableController = () => {
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const tableRef = useRef<any>(null);
  const [players, setPlayers] = useState<any>([]);
  const [columnWidth, setColumnWidth] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [averageByGame, setAverageByGame] = useState<any>('total');
  const { teamId } = useParams();
  const { selectedSeason, teamProfileMode } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const [playersLoading, setPlayersLoading] = useState(true);
  useEffect(() => {
    if (selectedSeason && teamId) {
      setPlayers([]);
      setPlayersLoading(true);
      ClubsAPI.getLineupStats(
        teamId,
        null,
        'current',
        selectedSeason.id,
        averageByGame,
      ).then((playersResponse) => {
        const playersList = playersResponse.data;
        setPlayers(playersList);
        setPlayersLoading(true);
      });
    }
  }, [selectedSeason, averageByGame, teamId]);
  useEffect(() => {
    if (tableRef && tableRef.current) {
      const el = tableRef.current;
      setColumnWidth((el?.getBoundingClientRect().width - 40 - 278) / 6);
    }
  }, [tableRef.current, teamProfileMode]);
  interface DataType {
    key: React.Key;
  }
  const columns: TableColumnsType<DataType> = [
    {
      dataIndex: 'number',
      key: 'number',
      title: t('#'),
      fixed: 'left',
      width: 40,
    },
    {
      dataIndex: 'full_name',
      key: 'name',
      title: t('Full name-player'),
      render: (text: string, record: any) => (
        <div
          className="enabledClickable underline"
          onClick={() => {
            window.open(`/players/${record.id}`, '_blank');
          }}
        >
          {text}
        </div>
      ),
      width: 278,
      fixed: 'left',
    },
    {
      dataIndex: 'nationality',
      key: 'nationality',
      title: t('Nationality'),
      className: 'left-column-border',
      width: columnWidth,
    },
    {
      dataIndex: 'age',
      key: 'age',
      title: t('Age'),
      width: columnWidth,
    },
    {
      dataIndex: 'role',
      key: 'role',
      title: t('Position'),
      render: (text) => <>{PLAYER_ROLES_ABBREVIATED[text]}</>,
      width: columnWidth,
    },
    {
      dataIndex: 'matches_count',
      key: 'matches_count',
      title: t('Matches played'),
      width: columnWidth,
    },
    {
      dataIndex: 'goal',
      key: 'goals',
      title: t('Goals'),
      width: columnWidth,
      // render: (text: number) => (text > 0 ? text : '—'),
    },
    {
      dataIndex: 'assist',
      key: 'assist',
      title: t('Assists_passes'),
      width: columnWidth,
      // render: (text: number) => (text > 0 ? text : '—'),
    },
    // {
    //   dataIndex: 'shot',
    //   key: 'shot',
    //   title: t('Shots'),
    //   render: (text: number) => (text > 0 ? text : '—'),
    // },
    // {
    //   dataIndex: 'pass',
    //   key: 'pass',
    //   title: t('Passes-p'),
    //   render: (text: number) => (text > 0 ? text : '—'),
    // },
    // {
    //   dataIndex: 'key_pass',
    //   key: 'key_pass',
    //   title: t('Key_pass-KP'),
    //   render: (text: number) => (text > 0 ? text : '—'),
    // },
  ];
  return (
    <div className="team-page-controller">
      <div className="block-wrapper players-table">
        <Flex ref={tableRef}>
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  rowHoverBg: 'rgba(0, 0, 0, 0.04)',
                  cellPaddingBlock: 12,
                  cellPaddingInline: 12,
                },
              },
            }}
          >
            <Table
              dataSource={players}
              columns={columns}
              style={{
                width: '100%',
                border: 'solid 1px rgba(0, 0, 0, 0.06)',
                borderRadius: 8,
              }}
              pagination={false}
              locale={{
                emptyText: playersLoading && (
                  <ConfigProvider>
                    <Spin
                      spinning={playersLoading}
                      size="large"
                      tip={t('Loading')}
                    >
                      <div style={{ padding: '25px' }}></div>
                    </Spin>
                  </ConfigProvider>
                ),
              }}
            />
          </ConfigProvider>
        </Flex>
      </div>
    </div>
  );
};
export default TeamPlayersTableController;
