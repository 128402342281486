import React from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';

const AverageStatTeamValueTag = ({
  stat,
  teamId,
}: {
  stat: any;
  teamId: string;
}) => {
  const [t] = useTranslation();
  return (
    <Flex
      className={`${stat[teamId]?.average === undefined ? 'na-stat' : stat[teamId]?.average > 0 ? 'average-stat-value-container' : 'stat-value-container-zero'}`}
      style={{ minWidth: 90, minHeight: 32, width: '100%' }}
      align="center"
    >
      <Flex
        className="stat-average"
        justify={
          stat[teamId]?.average !== undefined ? 'space-between' : 'center'
        }
        style={{ width: '100%' }}
        flex={1}
        align="center"
      >
        {stat[teamId]?.average === undefined ? (
          <span className="disabled-stat-color">{t('n/a')}</span>
        ) : stat[teamId].average > 0 ? (
          <span className="stat-average-value">{stat[teamId]?.average}</span>
        ) : (
          <span className="stat-average-value">0</span>
        )}

        {stat[teamId]?.percent !== null &&
          stat[teamId]?.percent !== undefined &&
          stat[teamId].average > 0 && (
            <span className={'team-stat-percent-value'}>
              {stat[teamId]?.percent}%
            </span>
          )}
      </Flex>
    </Flex>
  );
};
export default AverageStatTeamValueTag;
