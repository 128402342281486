/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import {
  Tooltip as ATooltip,
  ConfigProvider,
  Divider,
  Flex,
  Popover,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import './index.css';

import {
  FilterApply,
  GetPersonaAllMatches,
  GetSeasonPercentiles,
  GetSingleSeasonSelected,
  SetSelectedLastMatch,
  SetStatsState,
} from 'actions/persona.actions';
import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { useAppDispatch } from 'store';

import { makeStatsPlaceholders } from './const';
import PositionsFilter from './PositionsFilter';
import { PlayerStatisticsContent } from './SettingPopover';
import StatisticsDetailsBlock from './StatisticsDetailsBlock';
import { constructMatchesString } from './utils';

const PlayerStatisticsBlock = () => {
  const [t] = useTranslation();
  const { playerId } = useParams();
  const {
    openedTeamPlayer,
    positions,
    selectedPosition,
    positionsDurations,
    positionsLoading,
  } = useSelector((state: AppStateType) => state.teamPlayerReducer);
  const {
    selectedTournaments,
    teamPlayerStatsMatches,
    appliedLastMatch,
    statsState,
    selectedRowKeys,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const { setTeamPlayerStats, setAppliedSeasons } =
    teamPlayerStatsReducer.actions;
  const STATS_PLACEHOLDER = makeStatsPlaceholders(
    t,
    openedTeamPlayer?.team?.id,
  );
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<string | number>();
  const [selectedSeasonLabel, setSelectedSeasonLabel] = useState<string>();
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  // const [playerStats, setPlayerStats] = useState<any[]>(STATS_PLACEHOLDER);
  const [isLong, setIsLong] = useState<boolean>(false);
  const PopoverHandlerRef = React.createRef<any>();
  const {
    selectedLastMatch,
    selectedMatches,
    appliedSeasons,
    appliedTournaments,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const playerIdRef = useRef<any>(null);
  useEffect(() => {
    // console.log('setTeamPlayerStats -1');
    openedTeamPlayer &&
      dispatch(
        setTeamPlayerStats(makeStatsPlaceholders(t, openedTeamPlayer.team.id)),
      );
  }, [openedTeamPlayer]);
  useEffect(() => {
    // Инициализация
    if (playerId !== playerIdRef.current && playerId) {
      playerIdRef.current = playerId;
      dispatch(GetPersonaAllMatches(playerId));
    }
  }, [playerId]);

  const controllerRef = useRef<any>(null);
  const updateStats = async (seasonId: string) => {
    const controller: AbortController = new AbortController();
    const singleSeasonId = await dispatch(GetSingleSeasonSelected());
    if (
      singleSeasonId &&
      singleSeasonId !== 'manual' &&
      selectedRowKeys
      // selectedRowKeys.length === 0
    ) {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = controller;
      dispatch(
        FilterApply(
          t,
          singleSeasonId,
          [],
          playerId || '',
          controllerRef.current.signal,
        ),
      );
    } else if (
      singleSeasonId &&
      selectedRowKeys &&
      selectedRowKeys.length > 0
    ) {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = controller;
      dispatch(
        FilterApply(
          t,
          singleSeasonId,
          selectedRowKeys,
          playerId || '',
          controllerRef.current.signal,
        ),
      );
    } else if (statsState === 'loading') {
      const finalStats: any = [];
      STATS_PLACEHOLDER.forEach((stat: any) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          stat: null,
          excluded_sports: stat.excluded_sports,
          for_sport: stat.for_sport,
          disabled: stat.disabled,
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              excluded_sports: childStat.excluded_sports,
              for_sport: childStat.for_sport,
              disabled: childStat.disabled,
              stat: null,
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      console.log('setTeamPlayerStats A');
      dispatch(setTeamPlayerStats(finalStats));
      dispatch(SetStatsState('loaded'));
    }
  };
  useEffect(() => {
    const call = async () => {
      const seasonId = await dispatch(GetSingleSeasonSelected());
      if (playerId && seasonId !== 'manual') {
        dispatch(GetSeasonPercentiles(playerId, seasonId));
      }
    };
    call().then();
  }, [selectedPosition]);
  useEffect(() => {
    if (teamPlayerStatsMatches.length) {
      setSelectedSeason(teamPlayerStatsMatches[0].leagues[0]?.season_id);
      updateStats(teamPlayerStatsMatches[0].leagues[0]?.season_id);
      dispatch(setAppliedSeasons([teamPlayerStatsMatches[0]?.name]));
      setSelectedSeasonLabel(teamPlayerStatsMatches[0].name);
      const firstSeason =
        teamPlayerStatsMatches.length > 0
          ? `${teamPlayerStatsMatches[0].name}`
          : '';
      if (firstSeason && !selectedLastMatch) {
        // dispatch(
        //   setCheckedSeasons([
        //     `1-${appliedSeasons[0] || firstSeason}-${
        //       appliedTournaments[0] ||
        //       teamPlayerStatsMatches[0]?.leagues[0].tournament_id
        //     }`,
        //   ]),
        // );
        // dispatch(
        //   setSelectedTournaments([
        //     `1-${appliedSeasons[0] || firstSeason}-${
        //       appliedTournaments[0] ||
        //       teamPlayerStatsMatches[0]?.leagues[0].tournament_id
        //     }`,
        //   ]),
        // );
      }
    }
    const checkContentHeight = () => {
      if (PopoverHandlerRef.current) {
        setIsLong(PopoverHandlerRef.current.scrollWidth > 180);
      }
    };
    checkContentHeight();
  }, [teamPlayerStatsMatches]);

  const hide = () => {
    setOpen((prev) => false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen((prev) => newOpen);
  };
  return (
    <Flex className="block-wrapper right" gap={8} vertical>
      <Popover
        placement="left"
        content={
          <PlayerStatisticsContent
            hide={hide}
            isOpen={open}
            setSelectedSeason={setSelectedSeason}
            firstSeason={
              teamPlayerStatsMatches.length > 0
                ? `${teamPlayerStatsMatches[0].name}`
                : ''
            }
          />
        }
        open={open}
        overlayClassName="games-popover-overlay"
        trigger="contextMenu"
        onOpenChange={handleOpenChange}
      >
        <Flex className="block-title" align="center">
          <Flex gap={8} align="center" flex={1} justify="flex-start">
            <div className="block-title-h">{t('Statistics')}</div>
            <div
              ref={PopoverHandlerRef}
              className="playerStatsPopoverHandler"
              style={{ color: 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))' }}
              onClick={() => {
                setOpen((prev: boolean) => true);
                dispatch(SetSelectedLastMatch(appliedLastMatch));
              }}
            >
              {isLong && (
                <ATooltip
                  title={
                    teamPlayerStatsMatches.filter(
                      (s: any) => s.name === selectedSeasonLabel,
                    )[0]?.name
                  }
                  arrow={true}
                >
                  <div>
                    {
                      teamPlayerStatsMatches.filter(
                        (s: any) => s.name === selectedSeasonLabel,
                      )[0]?.name
                    }
                  </div>
                </ATooltip>
              )}
              {!isLong && (
                <div>
                  {selectedTournaments.length === 1
                    ? selectedTournaments[0].split('-')[1]
                    : ''}
                  &nbsp;
                  {constructMatchesString({
                    selectedRowKeys,
                    t,
                    selectedTournaments,
                  })}
                </div>
              )}
            </div>
          </Flex>
          <Flex align="center" gap={8} className="select-placeholder">
            <PositionsFilter
              positions={
                openedTeamPlayer
                  ? openedTeamPlayer.team.sport !== 2
                    ? positions
                    : []
                  : []
              }
              positionsDurations={positionsDurations}
              positionsLoading={positionsLoading}
              mode={'overview'}
            />
          </Flex>
        </Flex>
      </Popover>
      <div className="block-content">
        <div className="block-column block-colum-player-stats">
          <Flex className="block-column-title" justify="space-between">
            <Flex flex={1} className="stats-name">
              {t('Actions')}
            </Flex>
            <Flex gap={8} justify="space-between">
              <div
                className="stats-header"
                style={{
                  minWidth: 130,
                  textAlign: 'start',
                }}
              >
                {t('Total')}
              </div>
              {openedTeamPlayer && openedTeamPlayer.team.sport !== 2 && (
                <div style={{ width: 56 }} className="stats-header">
                  {t('90 min')}
                </div>
              )}
            </Flex>
          </Flex>
          <Divider style={{ margin: '0 0 8px 0', width: 'auto' }} />
          <StatisticsDetailsBlock
            selectedMetricFilters={selectedMetricFilters}
            selectedSeason={selectedSeason}
            setSelectedMetricFilters={setSelectedMetricFilters}
          />
        </div>
      </div>
    </Flex>
  );
};
export default PlayerStatisticsBlock;
