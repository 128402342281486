import React from 'react';

import ContentContainerV2 from 'components/ContentContainerV2';
import NotFoundController from 'components/NotFoundController';

const NotFoundPage = () => {
  return (
    <>
      <ContentContainerV2
        pageTitle={''}
        page={'teamProfile'}
        showNavigationPanel={true}
        style={{
          padding: '0 16px',
          maxWidth: 1648,
          minWdth: 1280,
        }}
      >
        <NotFoundController />
      </ContentContainerV2>
    </>
  );
};
export default NotFoundPage;
