import React, { useRef } from 'react';

import { useSelector } from 'react-redux';

// import { Col, Row } from 'antd';

import { AppStateType } from 'reducers';

import MapsAndStats from './MapsAndStats';
import MetricsSelector from './MetricsSelector';
import SeasonsAndGamesSelector from './SeasonsAndGamesSelector';

import './index.css';

const PlayerMultitool = ({
  playerProfileMode,
}: {
  playerProfileMode: string;
}) => {
  const metricsControllerRef = useRef<any>(null);
  const { selectedMetricFilters } = useSelector(
    (state: AppStateType) => state.teamPlayerStatsSectionReducer,
  );
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  return (
    <div className="multitool-content-grid">
      <div className="multitool-left-column">
        <div
          className="multitool-matches-column"
          style={{
            overflow: 'hidden overlay',
            height: 'calc(100vh - 96px - 56px - 12px)', // высота блока - игрок - шапка - отступ внизу
            maxHeight: 'calc(100vh - 96px - 56px - 12px)', // высота блока - игрок - шапка - отступ внизу,
          }}
        >
          <SeasonsAndGamesSelector
            metricsControllerRef={metricsControllerRef}
            selectedMetricFilters={selectedMetricFilters}
            playerProfileMode={playerProfileMode}
          />
        </div>
        <div className="multitool-metrics-column">
          <MetricsSelector metricsControllerRef={metricsControllerRef} />
        </div>
      </div>
      <div className="multitool-maps-column">
        {openedTeamPlayer && (
          <MapsAndStats
            metricsControllerRef={metricsControllerRef}
            playerProfileMode={playerProfileMode}
          />
        )}
      </div>
    </div>
  );
};
export default PlayerMultitool;
