import React, { FC, ReactElement, ReactPortal } from 'react';

import './index.css';
import { ConfigProvider } from 'antd';
import Layout, { Content } from 'antd/es/layout/layout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { Toast } from 'ui/Toast/Toast';

import NavigationPanelV2 from '../NavigationPanelV2';

type ReactText = string | number;
type ReactChild = ReactElement | ReactText;
type ReactNodeArray = Array<ReactNode>;
// eslint-disable-next-line @typescript-eslint/ban-types
type ReactFragment = {} | ReactNodeArray;
type ReactNode =
  | ReactChild
  | ReactFragment
  | ReactPortal
  | boolean
  | null
  | undefined
  | string;

type ContentContainerV2Interface = {
  pageTitle: string;
  page: any;
  showNavigationPanel?: boolean;
  styleMode?: string;
  rightPanel?: any;
  children?: any;
  style?: any;
};
const ContentContainerV2: FC<ContentContainerV2Interface> = (props) => {
  const { teamPlayerProfileMode } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const [t] = useTranslation();
  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            colorBgLayout: 'var(--colorBgLayout)',
          },
        },
      }}
    >
      <Layout style={{ height: '100%' }}>
        <NavigationPanelV2 page={props.page} />
        <Content
          className="page-content"
          style={{
            padding: props?.style?.padding || '0 48px',
            overflowY:
              props.page === 'playerProfile' &&
              teamPlayerProfileMode === t('Multitool')
                ? 'hidden'
                : 'auto',
          }}
        >
          {props.children}
          <Toast />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};
export default ContentContainerV2;
