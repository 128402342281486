import React, { useState } from 'react';

import { ConfigProvider, Dropdown, Flex, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { useAppDispatch } from 'store';

import { ReactComponent as ElipsisVertical } from '../../../assets/img/icons/faElipsisVThin2.svg';

const PlaylistContext = ({
  playlist,
  openRenameMode,
  movePlayToFolderCallback,
  downloadCallback,
  deleteCallback,
  shareCallback,
}: {
  playlist: any;
  openRenameMode: any;
  movePlayToFolderCallback: any;
  downloadCallback: any;
  deleteCallback: any;
  shareCallback: any;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [ddIsOpen, setDDIsOpen] = useState(false);
  const items: MenuProps['items'] = [
    {
      key: 1,
      label: (
        <div
          onClick={() => {
            openRenameMode(true);
            dispatch(AC_SetPlayerHotkeysAllowedAction(false));
          }}
        >
          {t('Rename')}
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={() =>
            downloadCallback(playlist.elId || playlist.key || playlist.id)
          }
        >
          {t('Download')}
        </div>
      ),
      disabled: true,
    },
    {
      key: 4,
      label: (
        <div
          onClick={() =>
            shareCallback(playlist.elId || playlist.key || playlist.id)
          }
        >
          {t('Share')}
        </div>
      ),
      disabled: false,
    },
    {
      key: 5,
      label: (
        <div
          onClick={() =>
            movePlayToFolderCallback(
              playlist.elId || playlist.key || playlist.id,
            )
          }
        >
          {t('Move to folder')}
        </div>
      ),
    },
    {
      key: 6,
      label: (
        <div
          onClick={() =>
            deleteCallback(playlist.elId || playlist.key || playlist.id)
          }
        >
          {t('Delete')}
        </div>
      ),
    },
  ];
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgElevated)',
            controlItemBgHover:
              'var(--controlItemBgHover, rgba(255, 255, 255, 0.08))',
            colorText: 'var(--colorText)',
          },
        }}
      >
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          overlayStyle={{ width: 'max-content' }}
          placement="bottomRight"
          onOpenChange={() => setDDIsOpen((prev) => !prev)}
          className="enabledClickable"
        >
          <Flex
            align="center"
            className={ddIsOpen ? 'activeButton' : 'enabledClickable'}
            style={{ width: 24, height: 24 }}
          >
            <ElipsisVertical width={24} />
          </Flex>
        </Dropdown>
      </ConfigProvider>
    </>
  );
};
export default PlaylistContext;
