/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

const FieldBlock = ({
  cell,
  fieldEventsDetails,
  toggleFieldCell,
  selectedToFieldCells,
  selectedFromFieldCells,
  type,
  selectionRect,
  fieldRef,
  setDragSelectedIds,
  playerProfileMode,
  widthSpan,
}: {
  cell: any;
  fieldEventsDetails: any;
  toggleFieldCell: any;
  selectedToFieldCells: any;
  selectedFromFieldCells: any;
  type: string;
  selectionRect: any;
  fieldRef: any;
  setDragSelectedIds: any;
  playerProfileMode: any;
  widthSpan: any;
}) => {
  const cellRef = useRef<any>(null);
  const [className, setClassName] = useState('');
  const [cellCoordinates, setCellCoordinates] = useState<any>(null);
  const { fromFieldEventsDetails, toFieldEventsDetails, selectedStat } =
    useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const getCellEventsCountFrom = (cellId: any) => {
    if (fromFieldEventsDetails[cellId]) {
      if (selectedToFieldCells.length === 0) {
        return fromFieldEventsDetails[cellId]['events']?.length || '';
      } else {
        if (selectedFromFieldCells.length === 0) {
          const len = fromFieldEventsDetails[cellId]['opposite'].filter(
            (oppositeCell: number) =>
              selectedToFieldCells.includes(oppositeCell),
          ).length;
          return len > 0 ? len : '';
        }
        if (selectedFromFieldCells.includes(cellId)) {
          const len = fromFieldEventsDetails[cellId]['opposite'].filter(
            (oppositeCell: number) =>
              selectedToFieldCells.includes(oppositeCell),
          ).length;
          return len > 0 ? len : '';
        }
        return '';
      }
    }
    return '';
  };
  const getCellEventsCountTo = (cellId: any) => {
    if (toFieldEventsDetails[cellId]) {
      if (selectedFromFieldCells.length === 0) {
        return toFieldEventsDetails[cellId]['events']?.length;
      } else {
        if (selectedToFieldCells.length === 0) {
          const len = toFieldEventsDetails[cellId]['opposite'].filter(
            (oppositeCell: number) =>
              selectedFromFieldCells.includes(oppositeCell),
          ).length;
          return len > 0 ? len : '';
        }
        if (selectedToFieldCells.includes(cellId)) {
          const len = toFieldEventsDetails[cellId]['opposite'].filter(
            (oppositeCell: number) =>
              selectedFromFieldCells.includes(oppositeCell),
          ).length;
          return len > 0 ? len : '';
        }
        return '';
      }
    }
    return '';
  };
  const constructCellClassName = (
    details: any,
    cellId: number,
    selectedCells: number[],
    oppositeCells: number[],
    type: string,
  ) => {
    let className = 'field-cell';
    if (type === 'to' && !getCellEventsCountTo(cellId)) {
      return className + ' non-selected';
    }
    if (type === 'from' && !getCellEventsCountFrom(cellId)) {
      return className + ' non-selected';
    }
    if (details[cellId]) {
      if (details[cellId]['events'].length) {
        className += ' with-value';
      }
      if (selectedCells.includes(cellId)) {
        className += ' selected';
      } else if (
        oppositeCells &&
        oppositeCells.filter((value) =>
          details[cellId]['opposite'].includes(value),
        ).length
      ) {
        className += ' opposite-selected';
      } else if (
        (selectedCells?.length && !selectedCells.includes(cellId)) ||
        (oppositeCells?.length && !selectedCells.includes(cellId))
      ) {
        className += ' non-selected';
      }
    }
    return className;
  };
  const getBackgroundColor = (
    cellDetails: any,
    cell: any,
    selectedCells: any,
    oppositeCells: any[],
    type: string,
  ) => {
    let baseColor;
    if (oppositeCells.length) {
      if (type === 'to' && !getCellEventsCountTo(cell.id)) {
        return baseColor;
      }
      if (type === 'from' && !getCellEventsCountFrom(cell.id)) {
        return baseColor;
      }
      if (selectedCells?.length && selectedCells.includes(cell.id)) {
        baseColor = `rgba(22, 119, 255, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      } else if (
        cellDetails[cell.id] &&
        oppositeCells &&
        oppositeCells.filter((value) =>
          cellDetails[cell.id]['opposite'].includes(value),
        ).length
      ) {
        // opposite-selected
        baseColor = `rgba(22, 119, 255, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      } else {
        // opposite-not-selected
        baseColor = `rgba(0, 0, 0, ${0.1 * cellDetails[cell.id]?.opacity + 0.0 || 0})`;
      }
    } else {
      if (type === 'from' && !getCellEventsCountFrom(cell.id)) {
        return baseColor;
      }
      if (selectedCells?.length && !selectedCells.includes(cell.id)) {
        // non-selected
        baseColor = `rgba(0, 0, 0, ${0.1 * cellDetails[cell.id]?.opacity + 0.0 || 0})`;
      } else {
        // with-value
        baseColor = `rgba(22, 119, 255, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
      }
    }
    return baseColor;
  };
  useEffect(() => {
    cellRef?.current &&
      setCellCoordinates(cellRef?.current.getBoundingClientRect());
  }, [
    cellRef,
    cellRef.current,
    fromFieldEventsDetails,
    toFieldEventsDetails,
    playerProfileMode,
    selectedStat,
  ]);
  useEffect(() => {
    const name = constructCellClassName(
      fieldEventsDetails,
      cell.id,
      type === 'from' ? selectedFromFieldCells : selectedToFieldCells,
      type === 'from' ? selectedToFieldCells : selectedFromFieldCells,
      type,
    );
    setClassName(name);
  }, [fieldEventsDetails, selectedFromFieldCells, selectedToFieldCells]);
  useEffect(() => {
    let count;
    if (type === 'from') {
      count = getCellEventsCountFrom(cell.id);
    } else {
      count = getCellEventsCountTo(cell.id);
    }
    if (selectionRect.width < 2 || selectionRect.height < 2) {
      setClassName((prev) => prev.replace(' drag-selected', ''));
    }
    if (
      count &&
      cellCoordinates &&
      (selectionRect.width >= 2 || selectionRect.height >= 2)
    ) {
      const container = fieldRef?.current.getBoundingClientRect();
      const rightOfBlock = cellCoordinates.right - container.left;
      const bottomOfBlock = cellCoordinates.bottom - container.top;
      const leftOfBlock = cellCoordinates.left - container.left;
      const topOfBlock = cellCoordinates.top - container.top;
      const intersects =
        selectionRect.x < rightOfBlock &&
        selectionRect.x + selectionRect.width > leftOfBlock &&
        selectionRect.y < bottomOfBlock &&
        selectionRect.y + selectionRect.height > topOfBlock;
      if (intersects && !className.includes('drag-selected')) {
        setClassName((prev) => prev + ' drag-selected');
        setDragSelectedIds((prev: number[]) => {
          if (!prev.includes(cell.id)) {
            return [...prev, cell.id];
          }
          return prev;
        });
      }
      if (!intersects && className.includes('drag-selected')) {
        setClassName((prev) => prev.replace(' drag-selected', ''));
        setDragSelectedIds((prev: number[]) =>
          prev.filter((el: number) => el !== cell.id),
        );
      }
    }
  }, [selectionRect, cellCoordinates, fieldRef, fieldRef?.current]);
  return (
    <Col
      ref={cellRef}
      key={cell.id}
      span={widthSpan}
      onClick={() => {
        if (
          fieldEventsDetails[cell.id] &&
          fieldEventsDetails[cell.id]['events']?.length !== undefined
        ) {
          toggleFieldCell(cell.id);
        }
      }}
      className={className}
      style={{
        backgroundColor: getBackgroundColor(
          fieldEventsDetails,
          cell,
          type === 'from' ? selectedFromFieldCells : selectedToFieldCells,
          type === 'from' ? selectedToFieldCells : selectedFromFieldCells,
          type,
        ),
      }}
    >
      <span className="cell-id">{cell.id}</span>
      <div className="value">
        {type === 'from'
          ? getCellEventsCountFrom(cell.id)
          : getCellEventsCountTo(cell.id)}
      </div>
    </Col>
  );
};
export default FieldBlock;
