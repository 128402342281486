import React, { useEffect, useRef, useState } from 'react';

import { Col } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { teamPlayerStatsSectionReducer } from 'reducers/teamPlayerStatsSection.reducer';
import { useAppDispatch } from 'store';

const CometFieldBlock = ({
  cell,
  fromFieldEventsDetails,
  as_point,
  rect,
  isSelecting,
  fieldRef,
  setDragSelectedIds,
  widthSpan,
}: {
  cell: any;
  fromFieldEventsDetails: any;
  as_point: any;
  rect: any;
  isSelecting: any;
  fieldRef: any;
  setDragSelectedIds: any;
  widthSpan: any;
}) => {
  const { multitoolEvents, selectedFromFieldCells, selectedToFieldCells } =
    useSelector((state: AppStateType) => state.teamPlayerStatsSectionReducer);
  const cellRef = useRef<any>(null);
  const [cellCoordinates, setCellCoordinates] = useState<any>(null);
  const { setSelectedFromFieldsCells, setMatchedPointEvents } =
    teamPlayerStatsSectionReducer.actions;
  const [className, setClassName] = useState('');
  const dispatch = useAppDispatch();
  const constructCellClassName = (
    details: any,
    cellId: number,
    selectedCells: number[],
    oppositeCells: number[],
  ) => {
    let className = 'field-cell';
    // console.log(cell);
    if (as_point && details[cellId]) {
      if (details[cellId]['events'].length) {
        className += ' with-value';
      }
      if (selectedCells.includes(cellId)) {
        className += ' selected';
      } else if (
        oppositeCells &&
        oppositeCells.filter((value) =>
          details[cellId]['opposite'].includes(value),
        ).length
      ) {
        className += ' opposite-selected';
      } else if (
        (selectedCells?.length && !selectedCells.includes(cellId)) ||
        (oppositeCells?.length && !selectedCells.includes(cellId))
      ) {
        className += ' non-selected';
      }
    }
    return className;
  };
  const toggleMapCell = async (cellId: number) => {
    if (isSelecting && (rect.width > 2 || rect.height > 2)) {
      return;
    }
    let updated = [];
    if (selectedFromFieldCells.includes(cellId)) {
      updated = selectedFromFieldCells.filter((el) => el !== cellId);
    } else {
      updated = [...selectedFromFieldCells, cellId];
    }
    const final = multitoolEvents.filter(
      (evt: any) =>
        updated
          .map((cellId: number) => fromFieldEventsDetails[cellId])
          .filter(
            (cell: any) => cell && cell.events && cell.events.includes(evt.id),
          ).length,
    );
    await dispatch(setMatchedPointEvents(final));
    await dispatch(setSelectedFromFieldsCells(updated));
    // dispatch(setSelectedEventsOrComets([]));
  };
  const getBackgroundColor = (
    cellDetails: any,
    cell: any,
    selectedCells: any,
    oppositeCells: any[],
  ) => {
    let baseColor;
    if (as_point) {
      if (oppositeCells.length) {
        if (selectedCells?.length && selectedCells.includes(cell.id)) {
          baseColor = `rgba(22, 119, 255, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
        } else if (
          cellDetails[cell.id] &&
          oppositeCells &&
          oppositeCells.filter((value) =>
            cellDetails[cell.id]['opposite'].includes(value),
          ).length
        ) {
          // opposite-selected
          baseColor = `rgba(22, 119, 255, ${0.4 * cellDetails[cell.id]?.opacity + 0.2 || 0})`;
        } else {
          // opposite-not-selected
          baseColor = `rgba(0, 0, 0, ${0.1 * cellDetails[cell.id]?.opacity + 0.0 || 0})`;
        }
      } else {
        if (selectedCells?.length && !selectedCells.includes(cell.id)) {
          // non-selected
          baseColor = `rgba(0, 0, 0, ${0.1 * cellDetails[cell.id]?.opacity + 0.0 || 0})`;
        } else {
          // with-value
          baseColor = `rgba(22, 119, 255, ${0.4 * cellDetails[cell.id]?.opacity + 0.1 || 0})`;
        }
      }
      // console.log(cell);
    }
    return baseColor;
  };
  const getCellEventsCountFrom = (cellId: any) => {
    if (fromFieldEventsDetails[cellId]) {
      if (selectedToFieldCells.length === 0) {
        return fromFieldEventsDetails[cellId]['events']?.length || '';
      } else {
        if (selectedFromFieldCells.length === 0) {
          const len = fromFieldEventsDetails[cellId]['opposite'].filter(
            (oppositeCell: number) =>
              selectedToFieldCells.includes(oppositeCell),
          ).length;
          return len > 0 ? len : '';
        }
        if (selectedFromFieldCells.includes(cellId)) {
          const len = fromFieldEventsDetails[cellId]['opposite'].filter(
            (oppositeCell: number) =>
              selectedToFieldCells.includes(oppositeCell),
          ).length;
          return len > 0 ? len : '';
        }
        return '';
      }
    }
    return '';
  };
  useEffect(() => {
    const name = constructCellClassName(
      fromFieldEventsDetails,
      cell.id,
      selectedFromFieldCells,
      selectedToFieldCells,
    );
    setClassName(name);
  }, [fromFieldEventsDetails, selectedFromFieldCells, selectedToFieldCells]);
  useEffect(() => {
    cellRef?.current &&
      setCellCoordinates(cellRef?.current.getBoundingClientRect());
  }, [cellRef, isSelecting]);
  useEffect(() => {
    if (as_point) {
      const count = getCellEventsCountFrom(cell.id);
      if (rect.width < 2 || rect.height < 2) {
        setClassName((prev) => prev.replace(' drag-selected', ''));
      }
      if (count && cellCoordinates && (rect.width >= 2 || rect.height >= 2)) {
        const container = fieldRef?.current.getBoundingClientRect();
        const rightOfBlock = cellCoordinates.right - container.left;
        const bottomOfBlock = cellCoordinates.bottom - container.top;
        const leftOfBlock = cellCoordinates.left - container.left;
        const topOfBlock = cellCoordinates.top - container.top;
        const intersects =
          rect.x < rightOfBlock &&
          rect.x + rect.width > leftOfBlock &&
          rect.y < bottomOfBlock &&
          rect.y + rect.height > topOfBlock;
        if (intersects && !className.includes('drag-selected')) {
          setClassName((prev) => prev + ' drag-selected');
          setDragSelectedIds((prev: number[]) => {
            if (!prev.includes(cell.id)) {
              return [...prev, cell.id];
            }
            return prev;
          });
        }
        if (!intersects && className.includes('drag-selected')) {
          setClassName((prev) => prev.replace(' drag-selected', ''));
          setDragSelectedIds((prev: number[]) =>
            prev.filter((el: number) => el !== cell.id),
          );
        }
      }
    }
  }, [rect, cellCoordinates]);
  return (
    <Col
      span={widthSpan}
      className={className}
      ref={cellRef}
      key={`comet-block-${cell.id}`}
      onMouseUp={() => {
        as_point &&
          fromFieldEventsDetails[cell.id] &&
          fromFieldEventsDetails[cell.id]['events']?.length &&
          toggleMapCell(cell.id);
      }}
      style={{
        backgroundColor: getBackgroundColor(
          fromFieldEventsDetails,
          cell,
          selectedFromFieldCells,
          selectedToFieldCells,
        ),
      }}
    >
      <span className="fs-12 lh-20 pl4 cell-id">{cell.id}</span>
      <div className="value cell-id">
        {(as_point &&
          fromFieldEventsDetails[cell.id] &&
          fromFieldEventsDetails[cell.id]['events']?.length) ||
          ''}
      </div>
    </Col>
  );
};
export default CometFieldBlock;
