import React, { useEffect, useState } from 'react';

import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

import { ReactComponent as FieldIconBlue } from '../../../assets/img/FieldIconBlue.svg';
import { ReactComponent as ChevronRight } from '../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircle } from '../../../assets/img/icons/faPlayCircle.svg';
import { ReactComponent as PlayCircleFilled } from '../../../assets/img/icons/faPlayCircleFilled.svg';
import { ReactComponent as PlayCircleFilledDisabled } from '../../../assets/img/icons/faPlayCircleFilledDisabled.svg';

export const TournamentMatchesBlock = () => {
  const [t] = useTranslation();
  const { setStatsCustomGameList } = teamReducer.actions;
  const { tournamentId } = useParams();
  const [matches, setMatches] = useState<any>([]);
  const { expandedGameClickedId } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const [expanded, setExpanded] = useState<string[]>(
    expandedGameClickedId && expandedGameClickedId.expanded
      ? [expandedGameClickedId.id]
      : [],
  );
  const toggleExpanded = (matchId: string) => {
    if (expanded.includes(matchId)) {
      setExpanded((prev) => prev.filter((el) => el !== matchId));
    } else {
      setExpanded((prev) => [...prev, matchId]);
    }
  };
  useEffect(() => {
    if (expandedGameClickedId !== null) {
      const gameDiv = document.getElementById(expandedGameClickedId.id);
      if (gameDiv) {
        gameDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start',
        });
      }
      if (expandedGameClickedId.expanded) {
        setExpanded([expandedGameClickedId.id]);
      }
    }
  }, [matches]);
  const dispatch = useAppDispatch();
  const openProtocol = () => {
    // dispatch(AC_SetGameProtocolIdAction("123"));
  };

  useEffect(() => {
    if (tournamentId) {
      setMatches([]);
      EventsAPI.getGameStatsBySeason(null, tournamentId).then(
        (matchesStatsResponse) => {
          if (matchesStatsResponse.data) {
            setMatches(matchesStatsResponse.data);
          }
        },
      );
    }
  }, [tournamentId]);
  const { setExpandedGameClickedId } = teamReducer.actions;
  const makeLink = (match: any, team?: any, stat?: any, part?: any) => {
    let link = `/player?&eventId=${match.id}`;
    if (team) {
      link = `${link}&teamId=${team?.id}&tid=${team.id}`;
    }
    if (stat) {
      link = `${link}&mid=${stat.metric_id}&m=${stat.key}`;
    }
    if (part) {
      link = `${link}&p=${part}`;
    }

    dispatch(AC_setIntermediateActivitiesAction([match.id]));
    dispatch(setStatsCustomGameList([match.id]));
    return link;
  };
  const navigate = useNavigate();
  return (
    <div className="block-wrapper left">
      <div className="block-title">
        <div>{t('Matches')}</div>
      </div>
      <div className="block-content flex-column">
        {matches.map((match: any) => (
          <div
            className={`team-match-wrapper ${expanded.includes(match.id) ? 'pressed' : 'enabledClickable'}`}
            onClick={() => {
              if (!expanded.includes(match.id)) {
                toggleExpanded(match.id);
              }
            }}
            id={match.id}
            key={match.id}
          >
            <div
              className="enabledClickable"
              onClick={() => toggleExpanded(match.id)}
            >
              <div
                style={{
                  width: 32,
                  height: 32,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexShrink: 0,
                }}
              >
                <ChevronRight
                  className={`${expanded.includes(match.id) ? 'rotate90' : 'rotate0'} `}
                  fill="var(--colorLink, #1677FF)"
                />
              </div>
              <div className="match-details">
                <div>{match.date}</div>
                <div
                  style={{
                    width: '100%',
                  }}
                  className="elipsis-text"
                >
                  {match.tournament?.country}
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                  className="elipsis-text"
                >
                  {match.tournament?.name}
                </div>
              </div>
              <div className="match-participants-container">
                <div style={{ textAlign: 'end', flex: 1, textWrap: 'nowrap' }}>
                  {match.teams[0].name}
                </div>
                <div
                  className="score"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div>{match.teams[0].score}</div>:
                  <div>{match.teams[1].score}</div>
                </div>
                <div
                  style={{ textAlign: 'start', flex: 1, textWrap: 'nowrap' }}
                >
                  {match.teams[1].name}
                </div>
              </div>
              <div className="enabledClickable">
                <FieldIconBlue
                  onClick={(e) => {
                    e.stopPropagation();
                    openProtocol();
                  }}
                />
              </div>
              <Divider type="vertical" style={{ height: 80 }} />
              <div
                className="buttons-controller"
                style={{ alignContent: 'center' }}
              >
                <div
                  className="enabledClickable"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setExpandedGameClickedId({
                        id: match.id,
                        expanded: expanded.includes(match.id),
                      }),
                    );
                    navigate(
                      makeLink(match, { id: tournamentId }, null, 'full'),
                    );
                  }}
                >
                  <PlayCircleFilled />
                  {t('Match')}
                </div>
                <div className="buttons-controller-disabled">
                  <PlayCircleFilledDisabled />
                  {t('Ball in game')}
                </div>
                <div
                  className="enabledClickable"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(
                      setExpandedGameClickedId({
                        id: match.id,
                        expanded: expanded.includes(match.id),
                      }),
                    );
                    navigate(
                      makeLink(match, { id: tournamentId }, null, 'highlights'),
                    );
                  }}
                >
                  <PlayCircleFilled />
                  {t('Highlights')}
                </div>
              </div>
            </div>
            {expanded.includes(match.id) && (
              <div className="flex-row f-ga-8">
                {match.teams.map((team: any, indexTeams: number) => (
                  <div className="match-skills-container" key={indexTeams}>
                    <div className="match-skills-details">
                      <div className="team-row">
                        <div className="team-name">{team.name}</div>
                        <div style={{ flex: 1 }} className="match-stats-block">
                          {team.stats
                            .filter((stat: any) => stat.show_in_game_stats)
                            .map((stat: any, indexStats: number) => (
                              <div
                                key={indexStats}
                                className="flex-column f-ga-4"
                                style={{
                                  minWidth: 99,
                                  height: 'auto',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <div className="stat-header">
                                  {t(stat.name)}
                                </div>
                                <div
                                  className={`  ${stat.value === null ? 'na-stat' : stat.value > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
                                  style={{ minWidth: 70 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (stat.value) {
                                      dispatch(
                                        setExpandedGameClickedId({
                                          id: match.id,
                                          expanded: expanded.includes(match.id),
                                        }),
                                      );
                                      navigate(makeLink(match, team, stat));
                                    }
                                  }}
                                >
                                  {stat.value ? (
                                    <PlayCircle height={32} />
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    style={{ flex: 1, height: 32 }}
                                    className={`${stat.value === null ? 'flex-row j-ce ai-c disabled-stat-color' : 'flex-row ai-c'}`}
                                  >
                                    {stat.value === null
                                      ? t('n/a')
                                      : stat.value}
                                  </div>
                                  <div className="stat-average">
                                    {stat.average !== undefined
                                      ? `${stat.average}%`
                                      : ''}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
