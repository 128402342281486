import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { makeLink } from 'components/PlayerPageController/PlayerStatisticsBlock/const';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

import { ReactComponent as PlayCircle } from '../../../assets/img/icons/faPlayCircle.svg';

import './index.css';

const MetricPlayerButtonWithPercent = ({
  statKey,
  stat,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  indexStats,
  teamId,
  match,
  setExpandedGameClickedId,
  selectedMetricFilters,
  lightVersion = false,
  teamType = '',
}: {
  statKey: any;
  stat: any;
  indexStats: number;
  teamId: string;
  match?: any;
  setExpandedGameClickedId?: any;
  selectedMetricFilters: any;
  lightVersion?: boolean;
  teamType: string;
}) => {
  const dispatch = useAppDispatch();
  const { expandedGames, selectedRowKeys } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const { setStatsCustomGameList } = teamReducer.actions;
  const [t] = useTranslation();
  const [displayedValue, setDisplayedValue] = useState(null);
  const className = `${displayedValue === null ? 'na-stat' : displayedValue > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'} ${lightVersion && displayedValue ? 'light-stat-value' : ''}`;
  const getMetricValue = () => {
    if (selectedMetricFilters.includes(`${statKey}_success`)) {
      if (stat?.details && stat?.details['success'][teamType]) {
        return stat?.details['success'][teamType]?.value;
      }
      return stat?.details['success'].value;
    }
    if (selectedMetricFilters.includes(`${statKey}_fail`)) {
      if (stat?.details && stat?.details['fail'][teamType]) {
        return stat?.details['fail'][teamType]?.value;
      }
      return stat?.details['fail'].value;
    }
    if (stat) {
      if (stat[teamType]) {
        return stat[teamType].value;
      }
      return stat.value;
    }
    return '';
  };
  useEffect(() => {
    setDisplayedValue(getMetricValue());
  }, [selectedMetricFilters, stat]);
  const makeLinkFromMatch = (
    match: any,
    team?: any,
    stat?: any,
    part?: any,
  ) => {
    let link = `/player?&eventId=${match.id}`;
    if (team) {
      if (part === 'highlights') {
        link = `${link}&teamId=${team?.id}`;
      } else {
        if (['ground_duel', 'aerial_duel'].includes(stat?.key)) {
          link = `${link}&teamId=${team?.id}`;
        } else {
          link = `${link}&teamId=${team?.id}&tid=${team.id}`;
        }
      }
    }
    if (stat) {
      if (selectedMetricFilters.includes(`${statKey}_success`)) {
        link = `${link}&m=${stat?.key}_success`;
      } else if (selectedMetricFilters.includes(`${statKey}_fail`)) {
        link = `${link}&m=${stat?.key}_fail`;
      } else {
        link = `${link}&m=${stat?.key}`;
      }
    }
    if (part) {
      link = `${link}&p=${part}`;
    }

    dispatch(AC_setIntermediateActivitiesAction([match.id]));
    dispatch(setStatsCustomGameList([match.id]));
    return link;
  };
  const makeLinkFromStats = (team?: any, stat?: any) => {
    const metricStringSuccess = `${stat.key}_success`;
    const metricStringFail = `${stat.key}_fail`;
    let finalMetric = [stat];
    if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringSuccess)
    ) {
      finalMetric = [{ key: metricStringSuccess }];
    } else if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringFail)
    ) {
      finalMetric = [{ key: metricStringFail }];
    }
    const link = makeLink(
      [{ id: null }],
      { id: teamId }, // if own team
      finalMetric,
      undefined,
      '',
      null,
      null,
      teamType === 'opponents' ? true : false, // if not own team
    );
    selectedRowKeys &&
      localStorage.setItem(
        `team_matches_${link}`,
        JSON.stringify(selectedRowKeys),
      );
    return link;
  };
  const getStatPercent = () => {
    if (stat && stat[teamType]) {
      return stat[teamType]?.percent !== undefined
        ? `${stat[teamType]?.percent}%`
        : '';
    }
    return stat?.percent !== undefined ? `${stat?.percent}%` : '';
  };
  return (
    <div
      className={className}
      style={{ minWidth: 70, zIndex: 10 }}
      onClick={(e) => {
        e.stopPropagation();
        if (displayedValue && match !== undefined) {
          dispatch(
            setExpandedGameClickedId({
              id: match.id,
              expanded: expandedGames.includes(match.id),
            }),
          );
          const link = makeLinkFromMatch(
            match,
            { id: teamId },
            { key: statKey },
          );
          window.open(link, '_blank');
        } else if (displayedValue) {
          const link = makeLinkFromStats({ id: teamId }, { key: statKey });
          window.open(link, '_blank');
        }
      }}
    >
      {displayedValue ? <PlayCircle height={32} /> : <></>}
      <div
        style={{ flex: 1, height: 32 }}
        className={`${displayedValue === null ? 'flex-row j-ce ai-c disabled-stat-color' : 'flex-row ai-c'}`}
      >
        {displayedValue === null ? t('n/a') : displayedValue}
      </div>
      <div className="stat-average">
        {!selectedMetricFilters.includes(`${statKey}_success`) &&
        !selectedMetricFilters.includes(`${statKey}_fail`) &&
        getMetricValue() !== 0
          ? getStatPercent()
          : ''}
      </div>
    </div>
  );
};
export default MetricPlayerButtonWithPercent;
