export const makeTeamStatsPlaceholders = (t: any, sportId: number) => {
  const SPORT_SPECIFIC_LABELS: any = {
    2: {
      goal_kick: t('Goal clearance'),
      out: t('Kick-ins'),
    },
  };
  const currentLabels = SPORT_SPECIFIC_LABELS[sportId];
  return [
    {
      label: t('Goals'),
      key: 'all_goal',
      status: 'loading',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Shots'),
      key: 'shot',
      status: 'loading',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Corners'),
      key: 'corner',
      status: 'loading',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Free kicks'),
      key: 'freekick',
      status: 'loading',
      excluded_sports: [],
      for_sport: [],
      children: [
        {
          label: t('direct freekicks'),
          status: 'loading',
          key: 'freekick_shot',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('indirect freekicks'),
          status: 'loading',
          key: 'freekick_pass',
          excluded_sports: [],
          for_sport: [],
        },
      ],
    },
    {
      label: currentLabels
        ? currentLabels['out'] || t('Throw-ins')
        : t('Throw-ins'),
      status: 'loading',
      key: 'out',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: currentLabels
        ? currentLabels['goal_kick'] || t('Goal kicks')
        : t('Goal kicks'),
      status: 'loading',
      key: 'goal_kick',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Penalties'),
      status: 'loading',
      key: 'penalty',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Double penalties'),
      stat: 'loading',
      key: 'double_penalty',
      for_sport: [2],
      excluded_sports: [],
      as_point: true,
    },
    {
      label: t('Passes'),
      key: 'pass',
      status: 'loading',
      excluded_sports: [],
      for_sport: [],
      children: [
        {
          label: t('assists'),
          status: 'loading',
          key: 'goal_pass',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('forward__'),
          status: 'loading',
          key: 'pass_forward',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('progressive'),
          status: 'loading',
          key: 'pass_progressive',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('key'),
          status: 'loading',
          key: 'key_pass',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('into 3/3'),
          status: 'loading',
          key: 'pass_to_3rd',
          excluded_sports: [2],
          for_sport: [],
        },
        {
          label: t('into 4/4'),
          stat: 'loading',
          key: 'pass_to_4th',
          for_sport: [2],
          excluded_sports: [],
          as_point: false,
        },
        {
          label: t('into pen. area'),
          status: 'loading',
          key: 'pass_to_box',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('crosses'),
          status: 'loading',
          key: 'pass_cross',
          excluded_sports: [2],
          for_sport: [],
        },
        {
          label: t('long'),
          status: 'loading',
          key: 'pass_long',
          excluded_sports: [],
          for_sport: [],
        },
      ],
    },
    {
      label: t('Dribbles'),
      status: 'loading',
      key: 'dribble',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Tackles'),
      status: 'loading',
      key: 'tackle',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Tackles 1v1'),
      status: 'loading',
      key: 'tackle1-1',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Interceptions'),
      status: 'loading',
      key: 'interception',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('2nd balls'),
      status: 'loading',
      key: 'recovery',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('Duels'),
      status: 'loading',
      key: 'duel',
      excluded_sports: [],
      for_sport: [],
      children: [
        {
          label: t('ground defensive'),
          status: 'loading',
          key: 'ground_duel_defence',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('aerial defensive'),
          status: 'loading',
          key: 'aerial_duel_defence',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('ground offensive'),
          status: 'loading',
          key: 'ground_duel_offence',
          excluded_sports: [],
          for_sport: [],
        },
        {
          label: t('aerial offensive'),
          status: 'loading',
          key: 'aerial_duel_offence',
          excluded_sports: [],
          for_sport: [],
        },
      ],
    },
    {
      label: t('Fouls'),
      status: 'loading',
      key: 'foul',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('YC'),
      status: 'loading',
      key: 'yellow_cards',
      excluded_sports: [],
      for_sport: [],
    },
    {
      label: t('RC'),
      status: 'loading',
      key: 'red_cards',
      excluded_sports: [],
      for_sport: [],
    },
  ];
};
